.buttonContainer {
  display: flex;
  .wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 14px 24px;
    font-family: 'Barlow';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.75px;
    color: #fff;
    & svg {
      padding-left: 12px;
    }
  }
}

@media screen and (min-width: 768px) {
  .buttonContainer {
    .wrapper {
    }
  }
}

@media screen and (min-width: 1024px) {
  .buttonContainer {
    .wrapper {
      &:hover {
        color: #2b2b2b;
        background-color: #fff;

        & path {
          fill: #2b2b2b;
        }
      }

      /*
      &:active {
        border: 1px solid #e8e8e8;
        background-color: #e8e8e8;
      }
      */
    }
  }
}
