.tabContainer {
  opacity: 1;
  transition: opacity 0.375s ease-in-out;

  &.hide {
    opacity: 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;

    .description {
      .title {
        text-align: center;
        font-size: 27px;
        font-weight: 600;
        letter-spacing: 0.2px;
      }

      .copy {
        padding-top: 24px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.6px;
      }
    }

    .dataGrid {
      padding-top: 48px;
    }
  }
}

@media screen and (min-width: 768px) {
  .tabContainer {
    .wrapper {
      .description {
        .title {
          font-size: 28px;
        }

        .copy {
        }
      }

      .dataGrid {
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .tabContainer {
    .wrapper {
      .description {
        .title {
        }

        .copy {
        }
      }

      .dataGrid {
      }
    }
  }
}
