.footnoteContainer {
  .wrapper {
    padding: 54px 0 0 0;

    .border {
      width: 45%;
      border-top: 1px solid #2b2b2b;
    }

    .notes {
      padding-top: 24px;
      li {
        padding-top: 16px;
        font-family: 'Barlow Condensed';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-align: left;

        &:first-of-type {
          padding-top: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .footnoteContainer {
    .wrapper {
      .border {
        width: 30%;
      }
    }
  }
}
