.sectionScrollerContainer {
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }

    &.alignTop {
      flex-direction: column;
      & > img {
        margin-top: 12px;
      }
      & > .copy {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }

    &.white {
      .copy {
        color: #f8f8f8;
      }
      & svg {
        circle {
          stroke: #f8f8f8;
        }
        path {
          stroke: #f8f8f8;
        }
      }
    }

    .copy {
      margin-right: 8px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.5px;
    }

    .svg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;

      svg {
        width: 32px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .sectionScrollerContainer {
    .wrapper {
      .copy {
        font-size: 22px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .sectionScrollerContainer {
    .wrapper {
    }
  }
}
