.highlightsContainer {
  opacity: 1;
  transition: opacity 0.375s ease-in-out;
  &.hide {
    opacity: 0;
  }

  .wrapper {
    display: flex;
    width: 100%;
    color: #f1f1f1;

    .item {
      width: 33.333%;
      &:last-of-type {
        padding-right: 0;
      }

      &.spacer {
        width: 24px;
      }

      .key {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 2.5px;
        text-align: center;
        text-transform: uppercase;
      }
      .value {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-align: center;
        padding-top: 8px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .highlightsContainer {
    .wrapper {
      .item {
        .key {
        }
        .value {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .highlightsContainer {
    .wrapper {
      .item {
        .key {
        }
        .value {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .highlightsContainer {
    .wrapper {
      .item {
        .key {
          font-size: 14px;
        }
        .value {
          font-size: 23px;
        }
      }
    }
  }
}
