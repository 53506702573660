.paragraphContainer {
  font-family: 'Barlow Condensed';
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.6px;
  color: #f1f1f1;
}

/*
@media screen and (min-width: 768px) {
  .paragraphContainer {
  }
}
*/

@media screen and (min-width: 1024px) {
  .paragraphContainer {
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.2px;
  }
}
