.horizontalScroller {
  position: relative;
  overflow: hidden;

  .wrapper {
    position: relative;
    width: 100%;
    .selector {
      display: flex;
    }
  }
}

@media screen and (min-width: 768px) {
  .tabSelectorContainer {
    .wrapper {
      .selector {
      }
    }
  }
}
