.rowContainer {
  .wrapper {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid #2b2b2b;

    .key {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 50%;
      padding-left: 16px;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.2px;
    }
    .value {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 50%;
      padding-right: 16px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.2px;
      word-break: break-all;

      .icon {
        display: flex;
        align-items: center;
        margin-right: 14px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .rowContainer {
    .wrapper {
      .key {
      }
      .value {
        .icon {
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .rowContainer {
    .wrapper {
      .key {
      }
      .value {
        .icon {
        }
      }
    }
  }
}
