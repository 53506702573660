@import 'scss/mixins.scss';

.landingPageContainer {
  header {
    position: relative;
    background-image: url('../../../public/images/backgrounds/background_grey_desktop.png');
    background-size: cover;

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(-180deg);
    }

    .wrapper {
      @include wrapper-mobile;
      padding: 68px 24px 38px;

      .logo {
        text-align: center;
      }
      .heading {
        max-width: 345px;
        padding-top: 52px;
        padding-bottom: 250px;
      }
      .search {
        .positioner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-sizing: border-box;
          width: calc(100% - 48px);
          max-width: 400px;
          padding-top: 148px;
        }
      }

      .sectionScroller {
        display: flex;
        margin: auto auto 0 auto;
      }
    }
  }

  .gemfairSection {
    position: relative;
    background-image: url('../../../public/images/backgrounds/gemfair_m.png');
    background-size: cover;
    background-position: center;
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.56);
    }
    .wrapper {
      @include wrapper-mobile;
      padding: 100px 24px;

      .positioner {
        .heading {
        }

        .copy {
          padding-top: 24px;
          text-align: center;
        }

        .button {
          display: flex;
          justify-content: center;
          padding-top: 40px;
        }
      }

      .sectionScroller {
        display: flex;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
      }
    }
  }

  .solutionSection {
    position: relative;
    background-image: url('../../../public/images/backgrounds/solution.png');
    background-size: cover;
    background-position: center;
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.56);
    }
    .wrapper {
      @include wrapper-mobile;
      padding: 100px 24px;

      .positioner {
        .copy {
          padding-top: 24px;
          text-align: center;
        }

        .button {
          display: flex;
          justify-content: center;
          padding-top: 40px;
        }
      }

      .sectionScroller {
        display: flex;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
      }
    }
  }

  .tracrSection {
    position: relative;
    background-image: url('../../../public/images/backgrounds/tracr_m.png');
    background-size: cover;
    background-position: center;

    .wrapper {
      @include wrapper-mobile;
      padding: 100px 24px;

      .positioner {
        .heading {
          padding-top: 48px;
        }

        .copy {
          padding-top: 24px;
          text-align: center;
        }

        .button {
          display: flex;
          justify-content: center;
          padding-top: 40px;
          padding-bottom: 80px;
        }
      }

      .logo {
        text-align: center;
        img {
          max-width: 262px;
        }
      }

      .sectionScroller {
        display: flex;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
      }
    }
  }

  .partnershipSection {
    position: relative;
    background-image: url('../../../public/images/backgrounds/partnership.png');
    background-size: cover;
    background-position: center;
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    }
    .wrapper {
      @include wrapper-mobile;
      padding: 100px 24px;

      .positioner {
        .copy {
          padding-top: 24px;
          text-align: center;
        }
      }

      .sectionScroller {
        display: flex;
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
      }
    }
  }

  .discoverSection {
    position: relative;
    background-image: url('../../../public/images/backgrounds/background_grey_desktop.png');
    background-size: cover;

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
      transform: rotate(-180deg);
    }

    .wrapper {
      @include wrapper-mobile;
      min-height: unset;
      padding-top: 62px;
      padding-bottom: 140px;

      .heading {
        max-width: 327px;
      }

      .search {
        width: 100%;
        .positioner {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          padding-top: 32px;
        }

        .message {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
          padding-top: 32px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .landingPageContainer {
    header {
      .wrapper {
        max-width: 768px;
        padding: 158px 56px 64px 56px;

        .logo {
          img {
            width: 226px;
          }
        }
        .heading {
          max-width: 435px;
          padding-top: 72px;
          padding-bottom: 400px;
        }
        .search {
          .positioner {
            max-width: 768px;
            width: 100%;
            padding: 156px 48px 0 48px;

            .searchWrapper {
              width: 400px;
              margin: 0 auto;
            }
          }
        }

        .secondaryHeading {
          br {
            display: none;
          }
        }
      }
    }

    .gemfairSection {
      background-image: url('../../../public/images/backgrounds/gemfair_d.png');
      .wrapper {
        max-width: 768px;
        padding: 150px 56px;

        .positioner {
          .heading {
          }

          .copy {
          }

          .button {
          }
        }

        .sectionScroller {
          bottom: 64px;
        }
      }
    }

    .solutionSection {
      .overlay {
      }
      .wrapper {
        justify-content: center;
        max-width: 768px;
        padding: 150px 56px;

        .positioner {
          .heading {
          }

          .copy {
          }

          .button {
          }
        }

        .sectionScroller {
          bottom: 64px;
        }
      }
    }

    .tracrSection {
      background-image: url('../../../public/images/backgrounds/tracr_d.png');
      .overlay {
      }
      .wrapper {
        max-width: 768px;
        padding: 120px 56px;

        .positioner {
          .logo {
            img {
              max-width: 311px;
            }
          }

          .heading {
            padding-top: 88px;
          }

          .copy {
          }

          .button {
          }
        }

        .sectionScroller {
          bottom: 64px;
        }
      }
    }

    .partnershipSection {
      .overlay {
      }
      .wrapper {
        max-width: 768px;
        padding: 150px 56px;

        .positioner {
          .heading {
          }

          .copy {
          }
        }

        .sectionScroller {
          bottom: 64px;
        }
      }
    }

    .discoverSection {
      .wrapper {
        max-width: 768px;
        padding-top: 120px;
        padding-bottom: 212px;

        .heading {
          max-width: unset;
        }

        .search {
          .positioner {
            width: 400px;
            margin: 0 auto;
            padding: 48px 0 0 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .landingPageContainer {
    header {
      .wrapper {
        max-width: 1024px;
        padding: 159px 57px 60px;

        .logo {
          img {
          }
        }
        .heading {
          max-width: 890px;
          padding-bottom: 400px;
        }
        .search {
          .positioner {
            max-width: 890px;
          }
        }

        .secondaryHeading {
          br {
            display: initial;
          }
        }

        .sectionScroller {
        }
      }
    }

    .gemfairSection {
      .wrapper {
        align-items: flex-start;
        max-width: 1280px;

        .positioner {
          width: 50%;
          .heading {
            text-align: left;
          }

          .copy {
            text-align: left;
          }

          .button {
            justify-content: flex-start;
          }
        }
      }
    }

    .solutionSection {
      .wrapper {
        align-items: flex-end;
        max-width: 1280px;

        .positioner {
          width: 50%;
          .heading {
            text-align: left;
          }

          .copy {
            text-align: left;
          }

          .button {
            justify-content: flex-start;
          }
        }
      }
    }

    .tracrSection {
      .wrapper {
        justify-content: center;
        max-width: 1280px;
        padding-top: 162px;
        padding-bottom: 190px;

        .positioner {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .logo {
            order: 1;
          }

          .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;

            .heading {
              padding: 0;
              text-align: left;
            }

            .copy {
              text-align: left;
            }

            .button {
            }
          }
        }

        .logo {
          img {
            width: 380px;
            max-width: unset;
          }
        }
      }
    }

    .partnershipSection {
      .overlay {
      }
      .wrapper {
        align-items: flex-end;
        max-width: 1280px;

        .positioner {
          width: 50%;
          .heading {
            text-align: left;
          }

          .copy {
            text-align: left;
          }

          .button {
            justify-content: flex-start;
          }
        }
      }
    }

    .discoverSection {
      .wrapper {
        max-width: 1280px;
        padding-top: 112px;
        padding-bottom: 144px;

        .heading {
          font-size: 46px;
        }
      }
    }
  }
}
