.avatarContainer {
  box-sizing: border-box;
  width: 237px;
  height: 237px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 12px 20px 0px #00000029;

  & img {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .avatarContainer {
    & img {
    }
  }
}

@media screen and (min-width: 1024px) {
  .avatarContainer {
    width: 285px;
    height: 285px;
    & img {
    }
  }
}
