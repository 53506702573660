body {
  margin: 0;
  font-family: 'Barlow Condensed', sans-serif;
  color: #2b2b2b;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  vertical-align: top;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  color: #fff;
}

a:visited {
  color: inherit;
}
