.searchContainer {
  .wrapper {
    display: flex;
    flex-grow: 0;
    width: 100%;
    height: 48px;
    border: 1px solid #939393;
    border-radius: 2px;
    box-sizing: border-box;

    &.focused {
      border: 1px solid #f2a901;
    }
    &.invalid {
      border: 1px solid #d50000;
    }

    input {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      border: none;
      padding: 0 0 0 12px;
      outline: none;
      font-family: 'Barlow Condensed', sans-serif;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0.5px;
      background-color: transparent;
      color: #121212;

      &.dark {
        color: #fff;
      }
    }

    .buttonClear {
      display: none;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 48px;
      margin-left: auto;
      cursor: pointer;

      &.visible {
        display: flex;
      }

      & path {
        fill: #484848;
      }

      &.dark {
        & path {
          fill: #fff;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }

      &.dark {
        &:hover {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }
    }

    .buttonSubmit {
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      width: 48px;
      background-color: #f2a901;
      cursor: pointer;

      &.disabled {
        background-color: transparent;

        & path {
          fill: #939393;
        }
      }

      &.disabled {
        cursor: not-allowed;
      }

      &:not(.disabled):hover {
        background-color: #de9c05;
      }
    }
  }

  .children {
    padding-top: 24px;
  }
}

@media screen and (min-width: 768px) {
  .searchContainer {
    .wrapper {
      input {
        font-size: 18px;
      }

      .buttonClear {
      }

      .buttonSubmit {
      }
    }

    .children {
      padding-top: 32px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .searchContainer {
    .wrapper {
      input {
      }
    }
  }
}
