@import 'scss/mixins.scss';

.diamondNotFoundContainer {
  header {
    position: relative;
    background-image: url('../../../public/images/backgrounds/diamond_header_placeholder.png');
    background-size: cover;

    .overlay {
      position: absolute;
      top: 0;
      bottom: 60px;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(180deg, rgba(255, 255, 255, 0.89) 0%, #ffffff 20.29%, rgba(255, 255, 255, 0) 100%);
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      width: 100%;
      min-height: 100vh;
      padding: 40px 16px 0 16px;
      z-index: 1;

      .logo {
        img {
          width: 125px;
        }
      }
      .search {
        width: 100%;
        max-width: 325px;
        padding-top: 32px;
      }

      .notFoundMSG {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 32px);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .diamondNotFoundContainer {
    header {
      .wrapper {
        padding: 56px 56px 0 56px;
      }
    }
  }
}
