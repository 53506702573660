@import 'scss/mixins.scss';

.footerContainer {
  footer {
    background-color: #2b2b2b;
    .wrapper {
      @include wrapper-mobile;
      min-height: unset;
      padding: 72px 0;

      .gemfairLogo {
        img {
          width: 150px;
        }
      }

      .socialMedia {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 24px;

        .linkToGemfair {
          font-size: 18px;
          font-weight: 400;
          line-height: 43px;
          letter-spacing: 0.5px;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
        }

        span {
          max-width: 115px;
          font-size: 18px;
          font-weight: 400;
          line-height: 43px;
          letter-spacing: 0.5px;
          text-align: center;
          text-transform: uppercase;
          color: #fff;
        }

        .links {
          display: flex;
          padding-top: 12px;
          li {
            padding-left: 24px;
            &:first-of-type {
              padding-left: 0;
            }

            img {
              width: 32px;
            }
          }
        }
      }

      .linksGroup {
        padding-top: 56px;

        &:nth-child(2n + 2) {
          padding-top: 24px;
        }

        .links {
          display: flex;
          flex-direction: column;
          align-items: center;

          li {
            display: flex;
            margin-top: 24px;
            &:first-of-type {
              margin: 0;
            }

            a {
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #fff;
            }
          }
        }
      }

      .rightsAndCreds {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 56px;

        .poweredBy {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.67px;
            text-transform: uppercase;
            color: #fff;
          }
          img {
            padding-left: 16px;
          }
        }
        .copyright {
          max-width: 200px;
          padding-top: 32px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.67px;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .footerContainer {
    footer {
      .wrapper {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: unset;
        padding: 72px 56px;

        .gemfairLogo {
          margin-right: 100%;
          margin-bottom: 46px;
          img {
            width: 200px;
          }
        }

        .socialMedia {
          align-items: flex-start;
          padding-top: 0;
          span {
            margin-top: 1px;
            text-align: left;
          }

          .links {
          }
        }

        .linksGroup {
          padding-top: 11px;
          padding-left: 56px;

          &:nth-child(2n + 2) {
            padding-top: 11px;
          }

          .links {
            align-items: flex-start;
            li {
              margin-top: 23px;
              &:first-of-type {
                margin: 0;
              }
            }
          }
        }

        .rightsAndCreds {
          width: 100%;
          flex-direction: row;
          width: 100%;
          padding: 72px 0 0 0;
          .poweredBy {
            order: 1;
            margin-left: auto;
            span {
            }
            img {
            }
          }
          .copyright {
            padding-top: 0;
            max-width: unset;
            br {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .footerContainer {
    footer {
      .wrapper {
        max-width: 1440px;
        padding: 88px 56px;

        .gemfairLogo {
          margin: 0;
        }

        .socialMedia {
          padding-left: 64px;

          .linkToGemfair {
            padding-top: 1px;
            font-size: 18px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.5px;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
          }

          span {
            margin-top: 13px;
          }

          .links {
            padding-top: 8px;
          }
        }

        .linksGroup {
          padding-top: 0;
          padding-left: 90px;

          &:nth-child(2n + 2) {
            padding-top: 0;
          }

          .links {
            li {
              margin-top: 22px;
              &:first-of-type {
                margin: 0;
              }
            }
          }
        }

        .rightsAndCreds {
          .poweredBy {
            span {
            }
            img {
            }
          }
          .copyright {
          }
        }
      }
    }
  }
}
