.dataGridContainer {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      text-align: center;
      font-size: 27px;
      font-size: 600;
      letter-spacing: 0.2px;
    }

    .grid {
      width: 100%;
      padding-top: 16px;
    }
  }
}

@media screen and (min-width: 768px) {
  .dataGridContainer {
    .wrapper {
      .title {
        font-size: 28px;
      }

      .grid {
      }
    }
  }
}
