.notFoundMSGContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: #2b2b2b;

    &.light {
      font-size: 28px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #f1f1f1;
    }
  }
  .description {
    padding: 8px 24px 0 24px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 26px;
    color: #2b2b2b;

    &.light {
      padding: 8px 0 0 0;
      font-size: 18px;
      font-weight: 400;
      color: #f1f1f1;
    }

    span {
      font-weight: 600;
    }
  }
}

@media screen and (min-width: 768px) {
  .notFoundMSGContainer {
    .title {
      &.light {
        font-size: 39px;
      }
    }
    .description {
      &.light {
      }
    }
  }
}
