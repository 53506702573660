.tabSelectorContainer {
  display: flex;
  justify-content: center;
  width: 100%;

  .padding {
    padding: 0 24px;
  }

  .tabs {
    display: flex;

    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      height: 44px;
      padding: 0 14px;
      border: 1px solid #2b2b2b;
      border-radius: 100px;
      font-family: 'Barlow Condensed', sans-serif;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.5px;
      white-space: nowrap;
      cursor: pointer;
      &.active {
        color: #fff;
        background-color: #2b2b2b;
      }
    }
    .spacer {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      width: 14px;
      max-width: 14px;
      background-image: url('../../../../public/images/backgrounds/tab_spacer.svg');
      background-position: center;
      background-repeat: repeat-x;
    }
  }
}

@media screen and (min-width: 768px) {
  .tabSelectorContainer {
    .padding {
      padding: 0 56px;
    }

    .tabs {
      .spacer {
        width: 24px;
        max-width: 24px;
      }
    }
  }
}
