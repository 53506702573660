@import 'scss/mixins.scss';

.diamondPageContainer {
  background-color: rgb(229, 229, 229, 0.3);

  header {
    position: relative;
    background-image: url('../../../public/images/backgrounds/diamond_header_placeholder.png');
    background-size: cover;

    .overlay {
      position: absolute;
      top: 0;
      bottom: 60px;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(180deg, rgba(255, 255, 255, 0.89) 0%, #ffffff 20.29%, rgba(255, 255, 255, 0) 100%);
    }

    .wrapper {
      @include wrapper-mobile;
      justify-content: initial;
      min-height: initial;
      padding: 40px 24px 148px 24px;

      .logo {
        img {
          width: 125px;
        }
      }
      .search {
        width: 100%;
        max-width: 400px;
        padding-top: 32px;
      }
      .heading {
        padding-top: 48px;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 46px;
        text-transform: uppercase;
        color: #f1f1f1;
        opacity: 1;
        transition: opacity 0.375s ease-in-out;
        &.hide {
          opacity: 0;
        }
      }

      .highlights {
        padding-top: 32px;
      }

      .avatar {
        position: absolute;
        bottom: -118px;
      }
    }
  }

  .tabSelector {
    padding: 158px 0 80px 0;
  }

  .tab {
    position: relative;
    box-sizing: border-box;
    padding: 0 24px 64px 24px;
  }
}

@media screen and (min-width: 768px) {
  .diamondPageContainer {
    header {
      .overlay {
      }

      .wrapper {
        max-width: 768px;
        padding: 55px 56px 200px 56px;

        .logo {
          img {
          }
        }
        .search {
          max-width: 325px;
        }
        .heading {
          padding-top: 64px;
          font-size: 32px;
          letter-spacing: 1.2px;
          line-height: 46px;
        }

        .highlights {
          width: 90%;
          padding-top: 48px;
        }

        .avatar {
        }
      }
    }

    .tabSelector {
      padding: 172px 0 56px 0;
    }

    .tab {
      max-width: 768px;
      margin: 0 auto;
      padding: 0 56px 80px 56px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .diamondPageContainer {
    header {
      .overlay {
      }

      .wrapper {
        max-width: 1024px;
        .logo {
          img {
          }
        }
        .search {
        }
        .heading {
        }

        .highlights {
          width: 100%;
        }

        .avatar {
          bottom: -142px;
        }
      }
    }

    .tabSelector {
      padding: 224px 0 72px 0;
    }

    .tab {
    }
  }
}

@media screen and (min-width: 1280px) {
  .diamondPageContainer {
    header {
      .overlay {
        bottom: 120px;
      }

      .wrapper {
        padding: 55px 56px 300px 56px;

        .logo {
          img {
          }
        }
        .search {
        }
        .heading {
          padding-top: 72px;
          font-size: 39px;
        }

        .highlights {
        }

        .avatar {
          bottom: -142px;
        }
      }
    }

    .tabSelector {
      padding: 224px 0 72px 0;
    }

    .tab {
    }
  }
}
