.headingContainer {
  text-align: center;
  font-family: 'Barlow Condensed';
  color: #2b2b2b;

  &.white {
    color: #fff;
  }
}

h1.headingContainer {
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

h2.headingContainer {
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

h3.headingContainer {
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

h4.headingContainer {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 28px;
}

@media screen and (min-width: 768px) {
  h1.headingContainer {
    font-size: 67px;
  }

  h3.headingContainer {
    font-size: 38px;
  }

  h4.headingContainer {
    font-size: 24px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1024px) {
  h1.headingContainer {
  }

  h3.headingContainer {
    font-size: 37px;
  }
}
